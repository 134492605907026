<template>
    <div class='app-container'>
        <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="送审单位:">
                <el-select v-model="search.institution" filterable size="small" clearable placeholder="请选择状态"
                    class="form-line-item">
                    <el-option v-for="item in institutionList" :key='item.institutionId' :value="item.institutionId"
                        :label="item.institutionName"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item style="margin-left:10px;">
                <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">
                    搜索
                </el-button>
                <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">
                    重置
                </el-button>
            </el-form-item>
        </el-form>
        <div>
            <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
                <template #buttons>
                    <el-button type="success" size="mini" icon="el-icon-plus" @click="insertReviewHandle">添加</el-button>
                </template>
            </vxe-toolbar>
            <!-- :loading="loading" -->
            <vxe-table class="mytable-scrollbar emailList" round align="left" ref="paperTable"
                highlight-current-row highlight-hover-row :keyboard-config="{isArrow: true}"
                :checkbox-config="{trigger: 'cell', highlight: true, range: false}" :loading='loading'
                :row-config="{isHover: true,isCurrent: true}"
                :tree-config="{transform: true, rowField: 'emailId', parentField: 'institutionId'}"
                :data="emailList">
                <!-- <vxe-column type='seq' title="序号" width="80" :show-overflow="'tooltip'"></vxe-column> -->
                <vxe-column field="emailId" title="ID" width="200" tree-node></vxe-column>
                <!-- <el-tree
                    :data="treeData"
                    :props="defaultProps"
                    :load="loadNode"
                    :highlight-current="true"
                    node-key="id"
                    show-checkbox
                    :default-checked-keys="checkedKeys"
                    ref="tree"
                    accordion
                    @node-click="handleNodeClick">
                </el-tree> -->
                <vxe-column field="templateInstitution" title="送审单位" width="300" :show-overflow="'tooltip'" sortable></vxe-column>
                <vxe-column field="templateDegree" title="学位类型" width="400" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column title="操作" min-width="200">

                    <template #default="{row}">
                        <el-button size="small" type="text" @click="routeTemplateCustom(row)">邮件定制
                        </el-button>
                        <el-popconfirm title="确定删除吗？" @confirm='removeTemplate(row)' style="margin-left:10px;">
                            <!-- <el-button >删除</el-button> -->
                            <el-button slot="reference" style="color:red;" size="small" type="text">删除</el-button>
                        </el-popconfirm>
                        <!-- <el-button size="small" type="text" @click="insertReviewHandle" style="margin-left:10px;">添加子单位</el-button> -->
                    </template>
                </vxe-column>
            </vxe-table>
            <vxe-pager perfect align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
                :total="page.total" :page-sizes="page.pageSizes"
                :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                @page-change="handlePageChange">
                <template #left>
                    <vxe-button size="small" @click="firstPage">首页
                    </vxe-button>
                </template>
            </vxe-pager>
        </div>
        <vxe-modal v-model="insertEmailModel" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom
            resize :transfer='true'>
            <template #title>
                添加邮件模板
            </template>
            <vxe-form :data="insertEmail" title-align="right" title-width="100">
                <vxe-form-item fieldname="institutionId" title="送审单位" :span="20" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-select v-model="data.institutionId" filterable placeholder="请选择" @change="institutionChangeHandle"
                            size="medium" transfer>
                            <vxe-option v-for="item in institutionList" :key='item.institutionId'
                                :value="item.institutionId" :label="item.institutionName"></vxe-option>
                        </vxe-select>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="degreeId" title="学位类型" :span="20" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-select v-model="data.degreeId" placeholder="请选择" size="medium" transfer>
                            <vxe-option v-for="item in degreeList" :key='item.degreeId' :value="item.degreeId"
                                :label="item.degreeName"></vxe-option>
                        </vxe-select>
                    </template>
                </vxe-form-item>
            </vxe-form>
            <template #footer>
                <el-button @click="cfmInsertReivewTypeHandle" type="primary" size="small">确认</el-button>
            </template>
        </vxe-modal>
    </div>
</template>

<script>
    export default {
        name: 'emailMenu',
        data() {
            return {
                loading: false,
                insertEmailModel: false,
                insertEmail: {
                    // templateName: '',
                    institutionId: '',
                    // reviewSystemId: '',
                    degreeId: '',
                },
                search: {
                    institution: ''
                },
                emailList: [],
                //分页
                page: {
                    currentPage: 1,
                    pageSize: 10,
                    pageCount: 40, //自己用
                    pageSizes: [1, 5, 10, 15, 20],
                    total: 100
                },
                institutionList: [],
                degreeList: [],
                reviewList: [],
                treeData: [],
                checkedKeys: [],
            }
        },
        created() {
            this.getEmailTemplatePreviews();
            this.getAllInstitutions();
            // this.getDegree();
            // this.getAllPatterns();
        },
        methods: {
            batchSearch() {
                this.getEmailTemplatePreviews();
            },
            resetHandle() {
                this.search = {
                    institution: ''
                }
            },
            insertReviewHandle() {
                this.insertEmailModel = true;
            },
            
            cfmInsertReivewTypeHandle() {
                let params = this.insertEmail;
                console.log(params);
                this.$api.schoolCustom.postAddEmailTemplate(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("添加成功");
                            this.getEmailTemplatePreviews();
                            this.insertEmailModel = false;
                        } else {
                            this.$message.warning("添加失败！");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            //加载树形结点
            // loadNode(node, resolve) {
            //     if (node.level === 0) {
            //         // 根节点
            //         // 根据分页参数获取总数据条数
            //         this.total = data.total;
            //         // 根据当前页码和每页条数查询数据
            //         const params = {
            //         page: this.currentPage,
            //         pageSize: this.pageSize
            //         };
            //         // 调用后台接口获取数据
            //         getData(params).then(response => {
            //         // 将数据赋值给根节点的children属性
            //         node.children = response.data.list;
            //         // 将节点数据返回给树形控件
            //         resolve();
            //         });
            //     } else if (node.level === 1) {
            //         // 二级节点
            //         // 根据节点id和分页参数获取数据
            //         const parentId = node.data.id;
            //         const params = {
            //         parentId: parentId,
            //         page: this.currentPage,
            //         pageSize: this.pageSize
            //         };
            //         // 调用后台接口获取数据
            //         getData(params).then(response => {
            //         // 将数据赋值给节点的children属性
            //         node.children = response.data.list;
            //         // 将节点数据返回给树形控件
            //         resolve();
            //         });
            //     } else {
            //         // 叶子节点
            //         // 返回空数组，表示不需要加载节点数据
            //         resolve([]);
            //     }
            //     },
            //获取email表
            getEmailTemplatePreviews() {
                this.loading = true;
                let params = {
                    institutionId: this.search.institution
                };
                this.$api.schoolCustom.getEmailTemplatePreviews(params)
                    .then(res => {
                        this.loading = false;
                        if (res.data.code) {
                            this.emailList = res.data.data;
                            this.page.total = this.emailList.length;
                            console.log(this.page.total);
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            getDegree() {
                let params = {
                    institutionId: this.insertEmail.institutionId
                };
                this.$api.schoolCustom.getDegree(params)
                    .then(res => {
                        this.degreeList = res.data.data;
                    }).catch(err => {
                        this.$message.warning("获取学位类型错误");
                    })
            },
            getAllInstitutions() {
                let params = {
                    pageIndex: 1,
                    pageSize: 20000
                }
                this.$api.schoolCustom.getAllInstitutions(params)
                    .then(res => {
                        this.institutionList = res.data.data;
                        // this.page.pageCount = res.data.count;
                    })
                    .catch(err => {
                        this.$message.warning('接口错误');
                    })
            },
            institutionChangeHandle({
                value,
                $event
            }) {
                this.insertEmail.degreeId = '';
                // this.insertEmail.reviewSystemId = '';
                this.getDegree();
                this.getAllPatterns();
            },
            getAllPatterns() {
                this.$api.reviewCustom.getAllPatterns()
                    .then(res => {
                        if (res.data.code == 200) {
                            this.reviewList = res.data.data;
                            // this.insertEmailModel = false;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },
            routeTemplateCustom(row) {
                this.$store.dispatch('custom/toggleInstitution', {
                    first: row.templateInstitution,
                    second: row.templateDegree
                });
                // this.$router.push('/EmailCustom');
                console.log(row)
                this.$router.push({
                    name: 'EmailCustom',
                    params: {
                        id: row.emailId
                    }
                })
            },
            // 删除模板
            removeTemplate(row) {
                let params = {
                    emailId: row.emailId
                };
                this.$api.schoolCustom.postDeleteEmailTemplate(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("删除成功！")
                            this.getEmailTemplatePreviews();
                        } else {
                            this.$message.warning("删除失败！");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护!");
                    })
            },
            //表格刷新
            refresh() {
                this.getEmailTemplatePreviews();
            },
            //分页操作
            handlePageChange({
                currentPage,
                pageSize
            }) {
                this.page.pageIndex = currentPage;
                this.page.pageSize = pageSize;
                this.getEmailTemplatePreviews();
            },
            firstPage() {
                this.page.pageIndex = 1;
                this.getEmailTemplatePreviews();
            },
            endPage() {
                this.page.pageIndex = parseInt(this.page.total / this.page.pageSize) + (this.page.total % this.page
                    .pageSize == 0 ? 0 : 1);
                this.getEmailTemplatePreviews();
            },
        },
        components: {

        }
    }
</script>

<style scoped>

</style>

<style>
    .emailList .vxe-table--body-wrapper {
        height: calc(100vh - 300px);
    }
</style>